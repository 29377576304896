import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Slider from 'components/slider';

const SliderContainer = ({ children, slides, location }) => (
  <Consumer>
    {({ activeSlide, prevSlide, nextSlide, goToSlide, slideDirection }) => (
      <Slider
        activeSlide={activeSlide}
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        goToSlide={goToSlide}
        slideDirection={slideDirection}
        slides={slides}
        location={location}
      />
    )}
  </Consumer>
);

SliderContainer.propTypes = {
  slides: PropTypes.array,
  activeSlide: PropTypes.number,
  slideDirection: PropTypes.string,
  prevSlide: PropTypes.func,
  nextSlide: PropTypes.func,
  goToSlide: PropTypes.func,
  location: PropTypes.object,
};

export default SliderContainer;
