import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  line-height: 1.15;
`;

// export const Text = styled.span`
//   font-size: 6vw;

//   ${MEDIA.DESKTOP`
//     font-size: 8vw;
//   `};

//   ${MEDIA.TABLET`
//     font-size: 10vw;
//   `};

//   ${MEDIA.PHONE`
//     font-size: 12vw;
//   `};
// `;

export const Text = styled.span`
  font-size: 6vw;
  line-height: 1.2;
`;
