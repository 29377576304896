import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PreLoad extends PureComponent {
  componentDidMount() {
    this.node.setAttribute('style', this.props.styleString);
  }
  render() {
    const { text } = this.props;
    return <div ref={node => (this.node = node)}>{text}</div>;
  }
}

PreLoad.propTypes = {
  text: PropTypes.string,
  styleString: PropTypes.string,
};

export default PreLoad;
