import React from 'react';
import PropTypes from 'prop-types';
import { SizeMe } from 'react-sizeme';
import { throttle, last } from 'lodash';

import { Container, Text } from './scaling-type.css';

function increaseFont(state, props) {
  return { fontSize: state.fontSize + 1 };
}

function showLines(node) {
  var lines = getLines(node);
  lines.map(line => (last(line).className = 'last'));
}

function splitLines(node) {
  var p = node.querySelector('p');
  p.innerHTML = p.innerText
    .split(/\s/)
    .map(function(word) {
      return '<span>' + word + '</span>';
    })
    .join(' ');
}

function getLines(node) {
  var lines = [];
  var line;
  var p = node.querySelector('p');
  var words = p.getElementsByTagName('span');
  var lastTop;
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    if (word.offsetTop != lastTop) {
      lastTop = word.offsetTop;
      line = [];
      lines.push(line);
    }
    line.push(word);
  }
  return lines;
}

class ScalingType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overflow: false,
      loaded: false,
      fontSize: 5,
    };
    this.fitText = this.fitText.bind(this);
    this.onResize = this.onResize.bind(this);
    this.handleOnResize = this.handleOnResize.bind(this);
  }

  fitText(node) {
    const el = node;
    const parent = node.parentElement;
    let fsize = 10;
    // size up
    while (el.clientHeight < parent.clientHeight) {
      fsize += 5;
      el.style.fontSize = fsize + 'px';
    }
    fsize -= 5;
    el.style.fontSize = fsize + 'px';
    // check for horizontal overflow
    while (el.clientWidth > parent.clientWidth) {
      console.log(fsize)
      fsize -= 5;
      el.style.fontSize = fsize + 'px';
    }
    if (
      typeof window !== 'undefined' &&
      window.innerHeight < window.innerWidth
    ) {
      splitLines(node);
      showLines(node);
      // check for weird breaks, orphans, etc.
      if (
        this.props.slug !== 'adelle-sans-arabic' &&
        this.props.slug !== 'balcony' &&
        this.props.slug !== 'tirhal' &&
        this.props.slug !== 'maiola' &&
        this.props.slug !== 'montiac'
      ) {
        const lastOfLines = node.getElementsByClassName('last');
        for (var line of lastOfLines) {
          let lineXPos =
            line.getBoundingClientRect().right -
            el.getBoundingClientRect().left;
          if (lineXPos < node.clientWidth / 2) {
            console.log('short line found');
            while (lineXPos < node.clientWidth / 2) {
              console.log('updating ' + fsize);
              fsize -= 5;
              el.style.fontSize = fsize + 'px';
              lineXPos =
                line.getBoundingClientRect().right -
                el.getBoundingClientRect().left;
            }
            // not sure if this is a fix or not, but it seems to work.
            splitLines(node);
            showLines(node);
          }
        }
      }
    }
  }

  componentDidMount() {
    this.node.setAttribute('style', this.props.styleString);
    this.fitText(this.node);
    typeof window !== 'undefined' &&
      window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.node.setAttribute('style', this.props.styleString);
    this.fitText(this.node);
    // const el = this.node;
    // const parent = this.node.parentElement;
    // if (this.state.fontSize < 20 && el.clientHeight < parent.clientHeight) {
    //   this.setState(increaseFont);
    //   console.log(this.state.fontSize);
    //   console.log('el.scrollHeight');
    //   console.log(el.clientHeight);
    //   console.log('parent.scrollHeight');
    //   console.log(parent.clientHeight);
    // }
  }

  componentWillUnmount() {
    typeof window !== 'undefined' &&
      window.removeEventListener('resize', this.onResize);
  }

  onResize = throttle(this.handleOnResize, 200, { trailing: true });

  handleOnResize() {
    this.fitText(this.node);
  }

  // TODO note.
  // This will have to load and scale sizes when resized. None of the plug-and-play solutions can handle multiple lines of text based on a height.

  render() {
    const { children, slug, location } = this.props;

    return (
      <Container>
        <div
          ref={node => (this.node = node)}
          key={slug}
          // style={{ fontSize: this.state.fontSize + 'vw' }}
        >
          {children}
        </div>
      </Container>
    );
  }
}

ScalingType.propTypes = {
  children: PropTypes.node,
};

export default ScalingType;
